<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-grid"/> {{caption}}
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="items"
        :fields="fields"
        :items-per-page="small ? 10 : 5"
        :dark="dark"
        pagination
      >
        <template #PDF="">
          <!-- <template {item}> -->
          <td>
            <CButton @click="generar_pdf()" block color="success">
              Descargar
            </CButton>
          </td>
           </template>
            
      </CDataTable>
    </CCardBody>
  </CCard>
</template>

<script>

export default {

  name: 'TableReferenciasSeguro',
  props: {
    items: Array,
    fields: {
      type: Array,
      default () {
        return ['referencia', 'fechaCaducidad', 'Estatus', 'PDF']
      }
    },
    caption: {
      type: String,
      default: 'TableReferenciaSeguro'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  data() {
    return {
      id:localStorage.getItem('id')
    }

  },
  methods: {
    generar_pdf(){

      open('https://cdn.upbicentenario.edu.mx/api/pdf_pago_seguro_nuevo_ingreso/' + this.id)

    },
    getBadge (status) {
      return status === 'Activa' ? 'success'
        : status === 'Inactive' ? 'secondary'
          : status === 'Pendiente de pago' ? 'warning'
            : status === 'Banned' ? 'danger' : 'primary'
    }
  },

}
</script>
